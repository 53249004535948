/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)
import { Modernizr } from "expose-loader?exposes=Modernizr!modernizr";


import { $, jQuery } from "expose-loader?exposes=$,jQuery!jquery";

import "jquery-migrate";

$ = window.$;
jQuery = window.jQuery;
Modernizr = window.Modernizr;

Modernizr.touch = Modernizr.touchevents;

import "application"

import 'slick-carousel/slick/slick.js'
import 'foundation-sites/js/foundation/foundation.js'
import 'foundation-sites/js/foundation/foundation.dropdown.js'
import 'foundation-sites/js/foundation/foundation.accordion.js'
import 'foundation-sites/js/foundation/foundation.offcanvas.js'

// Stimulus
import "controllers";

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();

  $.each($animation_elements, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);

    var bottom_percent        = $($element).data("animation-offset");
    var window_bottom_position;

    if( bottom_percent ) {
      window_bottom_position = (window_top_position + (bottom_percent * window_height));
    } else {
      window_bottom_position = (window_top_position + window_height);
    }

    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
        (element_top_position <= window_bottom_position)) {
      $element.addClass('in-view');
    } else {
      //$element.removeClass('in-view');
    }
  });
}

//Cache reference to window and animation items
var $animation_elements = $('.animation-element');
var $window = $(window);

Modernizr.on('webp', function(result) {
  $('img[data-webpurl]').each( function() {
    if( result ) {
      $(this).attr('src', $(this).data('webpurl'));
    } else {
      $(this).attr('src', $(this).data('url'));
    }
  });
});

window.addEventListener('load', (event) => {
  $('.slick-slider').slick({
    dots: true,
  });

  $(document).foundation();
});

if( $animation_elements.length > 0 ) {
  $window.on('scroll', check_if_in_view);
}


// Styles
import 'styles/foundation.scss'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import 'styles/fontawesome.less'
import 'styles/application.less'
