import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    actionName: String
  }

  static targets = [ "form", "tokenField" ];

  formSubmit(event){
    if( typeof(grecaptcha) != 'undefined') {
      event.preventDefault();
      let controller = this;

      grecaptcha.enterprise.ready(async () => {

        const token = await grecaptcha.enterprise.execute('6LeP7i0kAAAAAJRnjDwNjqrxL2kABZwtcYV2-6X6', {action: this.actionNameValue});
        controller.tokenFieldTarget.value = token;
        controller.formTarget.submit();
      });
    }
  }
}
